







import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "CompletedItem",
  props: {
    label: {
      type: String,
      required: true,
    },
    unfinishedLabel: {
      type: String,
      default: undefined,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
  },
});
