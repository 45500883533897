










































import { getProfile } from "@/api/userService";
import { useRouter } from "@/router";
import { userModule } from "@/store/modules/user";
import { computed, defineComponent, ref } from "@vue/composition-api";
import ViewProfile from "@/components/profile/ViewProfile.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  components: {
    ViewProfile,
  },
  setup: () => {
    const {
      currentRoute,
      navigation: { navigateToEditProfile },
    } = useRouter();
    const { userId } = currentRoute.value.params;
    const profileLoadError = ref(false);

    if (!userId || userId === userModule.state.userId) {
      return {
        canEdit: true,
        isLoading: false,
        navigateToEditProfile,
        profileLoadError,
        profile: computed(() => userModule.state.profile),
      };
    }
    else {
      const {
        isLoading,
        hasError,
        data: profile,
      } = useAsyncData(() => getProfile(userId));

      return {
        canEdit: false,
        isLoading,
        navigateToEditProfile,
        profileLoadError: hasError,
        profile,
      };
    }
  },
});
